import { Alert } from "@mui/material";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import BannerContext from "../../context/BannerContext";
import { AccessLevel } from "../../interfaces/IGetUserResult";

export default function FreeUpsellAlert() {


  const {
    data: { account },
  } = useContext(AuthContext);

  const {
    actions: { setProAlertOpen },
  } = useContext(BannerContext);

  const handleClick = () => {
    setProAlertOpen(true);
  };

  if (
    account?.access_level === AccessLevel.FREE &&
    process.env.REACT_APP_STRIPE_ENABLE === "true" &&
    account.verified
  )
    return (
      <>
        <Alert onClick={handleClick} severity="info" sx={{ cursor: "pointer" }}>
          Upgrade your free Starter account to Premium. <u>Click here</u> to
          check out all the great benefits of Nucleus Premium.
        </Alert>
      </>
    );
}
