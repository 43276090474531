export const STORAGE_AUTH_DATA = "user";
export const STORAGE_HOME_FORM = "home_form";

export const STORAGE_ACCOUNT_DATA = "account";
export const STORAGE_COMPANY_DATA = "company";
export const STORAGE_STRIPE_DATA = "stripe";
export const STORAGE_PARTNER_DATA = "partner";

export const STORAGE_BANNER_TRIAL_1 = "banner_trial_1";
export const STORAGE_BANNER_TRIAL_2 = "banner_trial_2";
export const STORAGE_BANNER_TRIAL_3 = "banner_trial_3";
