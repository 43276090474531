import { useContext } from "react";
import { AccessLevel } from "../interfaces/IGetUserResult";
import AuthContext from "../context/AuthContext";

export function usePro() {
  const {
    data: { account },
  } = useContext(AuthContext);
  return (
    account?.access_level === AccessLevel.PRO 
  );
}

export function useCanStripe() {
  const {
    data: { partner },
  } = useContext(AuthContext);
  return !(partner?.stripe_override === true);
}
