import React, { useContext, useState } from "react";
import useLocalStorage from "../hook/useLocalStorage";
import {
  STORAGE_BANNER_TRIAL_1,
  STORAGE_BANNER_TRIAL_2,
  STORAGE_BANNER_TRIAL_3,
} from "../const/LocalStorageKeys";
import AuthContext from "./AuthContext";

const BannerContext = React.createContext<{
  actions: {
    setIsOneClosed: (_: boolean) => void;
    setIsTwoClosed: (_: boolean) => void;
    setIsThreeClosed: (_: boolean) => void;
    setProAlertOpen: (_: boolean) => void;
  };
  data: {
    isOneClosed: boolean;
    isTwoClosed: boolean;
    isThreeClosed: boolean;
    proAlertOpen: boolean;
  };
}>({
  actions: {
    setIsOneClosed: () => {},
    setIsTwoClosed: () => {},
    setIsThreeClosed: () => {},
    setProAlertOpen: () => {},
  },
  data: {
    isOneClosed: false,
    isTwoClosed: false,
    isThreeClosed: false,
    proAlertOpen: false,
  },
});

interface BannerContextProviderProps {
  children: React.ReactNode;
}

export function BannerContextProvider(
  props: BannerContextProviderProps
): React.ReactElement {
  const { children } = props;
  const {
    data: { account },
  } = useContext(AuthContext);

  const [isOneClosed, setIsOneClosed] = useLocalStorage<boolean>(
    STORAGE_BANNER_TRIAL_1 + (account?.id || "guest"),
    false
  );
  const [isTwoClosed, setIsTwoClosed] = useLocalStorage<boolean>(
    STORAGE_BANNER_TRIAL_2 + (account?.id || "guest"),
    false
  );
  const [isThreeClosed, setIsThreeClosed] = useLocalStorage<boolean>(
    STORAGE_BANNER_TRIAL_3 + (account?.id || "guest"),
    false
  );
  const [proAlertOpen, setProAlertOpen] = useState(false);

  return (
    <BannerContext.Provider
      value={{
        actions: {
          setIsOneClosed,
          setIsTwoClosed,
          setIsThreeClosed,
          setProAlertOpen,
        },
        data: { isOneClosed, isTwoClosed, isThreeClosed, proAlertOpen },
      }}
    >
      {children}
    </BannerContext.Provider>
  );
}
export default BannerContext;
