import {
  Autocomplete,
  Chip,
  TextField,
} from "@mui/material";
import { useState } from "react";

interface TeamMemberAutocompleteProps {
  tagOptions: string[];
  value: string[];
  onChange: (value: string[]) => void;
}

export function TeamMemberAutocomplete(props: TeamMemberAutocompleteProps) {
  const { tagOptions, value, onChange } = props;

  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      multiple
      id="tags-filled"
      options={tagOptions}
      value={value || []}
      onChange={(e, newValue) => onChange(newValue)}
      defaultValue={[]}
      freeSolo
      sx={{ background: "white" }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        const options = newInputValue.split(",");

        if (options.length > 1) {
          onChange(
            value
              .concat(options)
              .map((x) => x.trim())
              .filter((x) => x)
          );
          setInputValue('');
        } else {
          setInputValue(newInputValue);
        }
      }}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              variant="outlined"
              label={option}
              key={key}
              sx={{ background: "#F8FAFE" }}
              {...tagProps}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Type a role (e.g. Sales, etc.) and push enter to save"
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-input": { background: "white" },
          }}
        />
      )}
    />
  );
}
