import { Box, Paper, Stack, Typography } from "@mui/material";
import { Premium } from "../img/Premium";
import { Beta } from "../img/Beta";
import { usePro } from "../utils/pro_utils";
export interface AvatarCardProps {
  disabled: boolean;
  name: string;
  selected: boolean;
  is_beta: boolean;
  is_premium: boolean;
  onClick: () => void;
}

export function AvatarCard(props: AvatarCardProps) {
  const { name, selected, is_beta, is_premium, disabled, onClick } = props;

  const isPro = usePro();

  const isDisabled = disabled || (!isPro && is_premium);

  const onPaperClick = () => {
    if (!isDisabled) onClick();
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: 1,
        width: 95,
        height: 44,
        boxShadow: selected ? "0 0 0  2px #5e6cb2" : "0 0 0  1px #cdcdcd",
        cursor: "pointer",
        opacity: isDisabled ? 0.3 : 1.0,
      }}
      onClick={onPaperClick}
      style={{ backgroundColor: is_premium ? "#E9ECF6" : "#FFF" }}
    >
      <Box sx={{ position: "relative" }}>
        {is_beta && (
          <Box
            sx={{
              position: "absolute",
              scale: "2",
              top: 6,

              right: 0,
            }}
          >
            <Beta />
          </Box>
        )}
        {is_premium && (
          <Box sx={{ position: "absolute", top: -20, right: -20 }}>
            <Premium />
          </Box>
        )}
      </Box>

      <Stack
        direction="column"
        spacing={1}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h5" sx={{ fontWeight: "normal" }}>
          {name}
        </Typography>
      </Stack>
    </Paper>
  );
}
