import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import PlainContainer from "../components/container/PlainContainer";
import AuthContext from "../context/AuthContext";
import { useNavigateToTop } from "../utils/utils";
import { LoadingCircle } from "../img/LoadingCircle";

const properties = {
  display: "grid",
  placeItems: "center",
  height: "100vh",
};

export default function EmailVerifyView() {
  const navigateToTop = useNavigateToTop();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");
  const token = searchParams.get("email-token");

  const [loading, setLoading] = useState(true);

  const [resendLoading, setResendLoading] = useState(false);
  const {
    data: { account, company },
    actions: { isLoggedIn, forgotPassword },
  } = useContext(AuthContext);

  const {
    actions: { verifyEmail },
  } = useContext(AuthContext);

  const checkToken = () => {
    if (!isLoggedIn() || (isLoggedIn() && company?.name)) {
      //Using this as a marker of loading
      if (account?.username === email && account?.verified) {
        navigateToTop("/complete");
      } else if (email && token) {
        verifyEmail(email!, token!)
          .then(() => {
            navigateToTop("/complete");
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    timeout = setTimeout(() => {
      checkToken();
    }, 50);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEmailVerify = () => {
    if (email) {
      setResendLoading(true);
      forgotPassword(email)
        .then(() => {})

        .finally(() => {
          setResendLoading(false);
        });
    }
  };

  if (loading) {
    return (
      <Box sx={properties}>
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <PlainContainer>
      <Grid item xs={12} spacing={3} container direction="column">
        <Grid item>
          <Typography variant="h2" align="center">
            The link has expired
          </Typography>
        </Grid>
        <Grid item>
          <Typography align="center">
            If you didn’t have time to create an account, contact your admin. If
            you already have an account log in.{" "}
            <u style={{ cursor: "pointer" }} onClick={handleEmailVerify}>
              Resend
            </u>
            {resendLoading && <LoadingCircle sx={{ display: "inline" }} />}
          </Typography>
        </Grid>
        <Grid item>
          <RouterLink to="/signin">
            <Button>Back to Login</Button>
          </RouterLink>
        </Grid>
      </Grid>
    </PlainContainer>
  );
}
