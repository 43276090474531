import { Stack } from "@mui/material";
import { useFieldArray, useFormContext } from "react-hook-form";
import { IHomeFormInput } from "../../interfaces/IHomeFormInput";
import { ModelCardLayout } from "../ModelCardLayout";
import { usePro } from "../../utils/pro_utils";

export interface ModelCardSectionProps {}

export function ModelCardSection() {
  const isPro = usePro();


  const { control } = useFormContext<IHomeFormInput>();
  useFieldArray({
    control,
    name: "cards",
  });

  const cardsDisabled = !isPro;

  return (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        spacing={2}
      >
        <ModelCardLayout disabled={cardsDisabled} />
      </Stack>
    </>
  );
}
