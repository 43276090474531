import { Alert, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import BannerContext from "../../context/BannerContext";
import { AccessLevel } from "../../interfaces/IGetUserResult";
import { ProAlertDialog } from "../dialog/ProAlertDialog";
import LearnHowAlert from "./LearnHowAlert";
import { useCanStripe, usePro } from "../../utils/pro_utils";

interface ProTrialAlertProps {}
const TRIAL_ONE_SECONDS = 30;
export default function ProTrialAlert(props: ProTrialAlertProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const isPro = usePro();
  const canStripe = useCanStripe();
  const traildays = parseInt(
    process.env.REACT_APP_NEW_ACCOUNT_TRIAL_DAYS || "0"
  );

  const [proTime, setProTime] = useState(0);
  const [promoCodeTime, setPromoCodeTime] = useState(0);
  const [proSeconds, setProSeconds] = useState(0);

  const {
    data: { account, stripe },
  } = useContext(AuthContext);

  const {
    actions: { setIsOneClosed, setIsTwoClosed, setProAlertOpen },
    data: { isOneClosed, isTwoClosed },
  } = useContext(BannerContext);

  useEffect(() => {
    let secTimeout: NodeJS.Timeout | undefined = undefined;
    const trial_start = dayjs.utc(stripe?.trial_start);
    const trial_end = dayjs.utc(stripe?.trial_end);
    const promo_code_expiry = dayjs.utc(stripe?.promo_code_expiry);

    if (isPro && canStripe && trial_start && trial_end) {
      const now = dayjs().utc();
      if (now.isBetween(trial_start, trial_end)) {
        setProTime(Math.ceil(trial_end.diff(now, "hour") / 24));
        setPromoCodeTime(Math.ceil(promo_code_expiry.diff(now, "hour") / 24));

        const proSeconds = Math.ceil(now.diff(trial_start, "seconds"));
        setProSeconds(proSeconds);
      }

      secTimeout = setTimeout(() => {
        const now = dayjs().utc();
        const trial_start = dayjs.utc(stripe?.trial_start);
        const proSeconds = Math.ceil(now.diff(trial_start, "seconds"));
        setProSeconds(proSeconds);
      }, TRIAL_ONE_SECONDS * 1000);
    }

    return () => {
      if (secTimeout) clearTimeout(secTimeout);
    };
  }, [
    account?.access_level,
    stripe?.promo_code_expiry,
    stripe?.trial_end,
    stripe?.trial_start,
  ]);

  const handleClick = () => {
    if (!stripe?.has_billing) {
      if (stripe?.promo_code && promoCodeTime > 0) {
        //Go directly to store
        navigate("/upgrade?promo_code=" + stripe?.promo_code);
      } else {
        // Show dialog first
        setProAlertOpen(true);
      }
    }
  };

  const getSubsection = () => {
    if (!stripe?.has_billing) {
      if (stripe?.promo_code && promoCodeTime > 0) {
        return (
          <>
            Use promo code {stripe?.promo_code} within {promoCodeTime} day
            {promoCodeTime > 1 ? "s" : ""} to get a {stripe?.promo_code_percent}
            % discount.
          </>
        );
      } else {
        return <>See all the benefits that come with your Premium account.</>;
      }
    }
    return <></>;
  };

  if (isPro && canStripe) {
    if (proTime > 0) {
      if (!isOneClosed && proSeconds < TRIAL_ONE_SECONDS) {
        return (
          <Alert
            severity="info"
            sx={{ cursor: "pointer", svg: { display: "inline" } }}
            onClose={(e) => {
              e.stopPropagation();
              setIsOneClosed(true);
            }}
          >
            Congratulations, you’ve activated your free Starter account. You’ve
            been selected to receive access to Premium features free for{" "}
            {traildays} day
            {traildays > 1 ? "s" : ""}.
          </Alert>
        );
      }
    }
  }

  const showTrialBanner =
    !isTwoClosed && !stripe?.has_billing && isPro && canStripe && proTime > 0;

  return (
    <>
      {showTrialBanner && (
        <Alert
          onClick={handleClick}
          severity="success"
          sx={{
            cursor: "pointer",
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          }}
          onClose={(e) => {
            e.stopPropagation();
            setIsTwoClosed(true);
          }}
        >
          You have {proTime} day{proTime > 1 ? "s" : ""} left in your Premium
          Trial. {getSubsection()} <u>Click here</u>
        </Alert>
      )}
      <LearnHowAlert />
    </>
  );
}
