import { Alert } from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import { useContext } from "react";
import { ErrorAlert } from "./alert/ErrorAlert";
import ApiContext from "../context/ApiContext";
import SuspendAlert from "./alert/SuspendAlert";
import GuestAlert from "./alert/GuestAlert";
import FreeUpsellAlert from "./alert/FreeUpsellAlert";
import VerifyEmailAlert from "./alert/VerifyEmailAlert";
import ProTrialAlert from "./alert/ProTrialAlert";
import { ProAlertDialog } from "./dialog/ProAlertDialog";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

interface BannerStackProps {}

export function BannerStack(props: BannerStackProps) {
  const {
    data: { success, error, warning },
  } = useContext(ApiContext);

  return (
    <>
      <ProAlertDialog/>
      {success && <Alert severity="success">{success} </Alert>}
      {warning && <Alert severity="warning">{warning} </Alert>}
      <ErrorAlert id="errorAlert" error={error} />
      <SuspendAlert />
      <GuestAlert />
      <FreeUpsellAlert />
      <VerifyEmailAlert />
      <ProTrialAlert />
    </>
  );
}
