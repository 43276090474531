import {
  Box,
  Dialog,
  Grid,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { theme } from "../../theme";
import React from "react";
import { NucleusButton } from "../NucleusButton";
import {
  PRO_YEAR_PRICE_NAME,
  PRO_MONTH_PRICE_NAME,
} from "../../context/ApiContext";
import { useNavigate } from "react-router-dom";
import BannerContext from "../../context/BannerContext";

export interface ProAlertDialogProps {}

export function ProAlertDialog(props: ProAlertDialogProps) {
  const navigate = useNavigate();
  const {
    actions: { setProAlertOpen },
    data: { proAlertOpen },
  } = useContext(BannerContext);

  const [monthYear, setMonthYear] = useState(PRO_YEAR_PRICE_NAME);
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newMonthYear: string
  ) => {
    if (newMonthYear) setMonthYear(newMonthYear);
  };

  const onOpenStore = (lookup_key: string) => {
    navigate("/upgrade?lookup_key=" + lookup_key);
  };

  return (
    <Dialog
      open={proAlertOpen}
      onClose={() => {
        setProAlertOpen(false);
      }}
      PaperProps={{
        sx: { border: "none" },
      }}
      maxWidth={false}
    >
      <Paper
        sx={{
          width: { xs: 320, sm: 500, md: 700, lg: 700 },
          padding: theme.spacing(2),
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6} order={{ xs: 2, sm: 1 }}>
            <Stack
              direction="column"
              spacing={0}
              justifyContent="center"
              alignItems="stretch"
            >
              <article>
                <Typography variant="h3">Starter</Typography>
                <Box sx={{ minHeight: "55px" }}>
                  <Typography>
                    Ideal for startups and solo enterpreneurs
                  </Typography>
                </Box>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="h2">FREE</Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50px",
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                ></Stack>
                <div className="">
                  What is included?
                  <ul className="">
                    <li>Interactive natural language AI attendant</li>
                    <li>New US or Canadian phone number</li>
                    <li>100% incoming calls answered</li>
                    <li>
                      Calls transferred to any team member in US or Canada*
                    </li>
                    <li>Add up to 10 team members</li>
                    <li>Set your team's available hours to take live calls</li>
                    <li>
                      Get messages outside of available hours through email or
                      text
                    </li>
                    <li>Noise cancellation filter on all calls</li>
                    <li>Primary speaker detection</li>
                    <li>Unlimited usage</li>
                  </ul>
                </div>
              </article>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, sm: 2 }}>
            <Stack
              direction="column"
              spacing={0}
              justifyContent="center"
              alignItems="stretch"
            >
              <article>
                <Typography variant="h3">Premium</Typography>

                <Box sx={{ minHeight: "55px" }}>
                  <Typography>Ideal for growing businesses</Typography>
                </Box>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="h2" sx={{ width: "176px" }}>
                    US$20{monthYear === PRO_YEAR_PRICE_NAME ? "0" : ""}
                  </Typography>
                  <Stack
                    direction="column"
                    sx={{
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box>
                      <Typography>per</Typography>
                    </Box>
                    <Box>
                      <Typography>
                        {monthYear === PRO_YEAR_PRICE_NAME ? "Year" : "Month"}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50px",
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={monthYear}
                    exclusive
                    aria-label="Platform"
                    onChange={handleChange}
                  >
                    <ToggleButton value={PRO_MONTH_PRICE_NAME}>
                      Monthly
                    </ToggleButton>
                    <ToggleButton value={PRO_YEAR_PRICE_NAME}>
                      Yearly
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
                <div className="">
                  Includes all free features +
                  <ul className="">
                    <li>Bring your existing business number</li>
                    <li>
                      Customise your attendant greetings for a tailored
                      experience
                    </li>
                    <li>
                      Teach your AI attendant about your business to offer more
                      customised responses to callers
                    </li>
                    <li>Additional high-quality voice options</li>
                    <li>Add up to 100 team members</li>
                    <li style={{ listStyleType: "none" }}>⠀</li>
                    <li style={{ listStyleType: "none" }}>⠀</li>
                    <li style={{ listStyleType: "none" }}>⠀</li>
                    <li style={{ listStyleType: "none" }}>⠀</li>
                    <li style={{ listStyleType: "none" }}>⠀</li>
                    <li style={{ listStyleType: "none" }}>⠀</li>
                  </ul>
                </div>
                <NucleusButton
                  variant="contained"
                  sx={{ width: "100%" }}
                  type="button"
                  onClick={() => onOpenStore(monthYear)}
                >
                  Continue with Premium
                </NucleusButton>
              </article>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </Dialog>
  );
}
